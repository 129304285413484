import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Grid } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { AuthService } from '../../services/AuthService';
import { makeStyles } from '@mui/styles';

import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import displayError from '../../errors/displayError';
import { setLogin } from '../../browser/localStorage';
import useAppContext from '../../hooks/useAppContext';


const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{12,24}$/;


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  middle: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword() {
  const classes = useStyles();
  const { setAuth, setStore } = useAppContext();
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [matchPassword, setMatchPassword] = useState('');
  const [validMatch, setValidMatch] = useState(false);

  const [errMsg, setErrMsg] = useState('');

  const [eye, setEye] = useState(false);
  const [matchEye, setMatchEye] = useState(false);

  const navigate = useNavigate();
  const query = useQuery();

  const handleEye = () => {
    setEye(!eye);
  }

  const handleMatchEye = () => {
    setMatchEye(!matchEye);
  }

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPassword);
    setErrMsg('');
  }, [password, matchPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack
    const v1 = PWD_REGEX.test(password);
    if (!v1) {
      setErrMsg("Invalid Pasword");
      return;
    } else if (password !== matchPassword) {
      setErrMsg("Password does not match");
      return;
    }

    AuthService.createPasswordAndLogin(password, query.get('token'), query.get('email'))
      .then(response => {
        const { store, auth } = response.data;
        setAuth(auth);
        setStore(store);

        setLogin();
        navigate('/orders');
      })
      .catch(err => {
        displayError(err, setErrMsg);
      });
  };


  return (
    <>
      <p className={errMsg ? "alert-danger login-container" : "offscreen"}>{errMsg}</p>
      <h1>Create your Password</h1>
      <form className="login-container" onSubmit={(e) => handleSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                width: '100%',
              }}
            >
              {`Your're almost in to your new account! But first, we need you to set a password for your
              ${process.env.REACT_APP_NAME} account so it's secure. Please set your password below to continue.`}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <TextField
              required
              type={eye ? "text" : "password"}
              label="Password"
              variant="outlined"
              size="small"
              name="password"
              value={password || ''}
              sx={{
                width: '100%'
              }}
              onChange={(e) => setPassword(e.target.value)}
              error={!!password && !validPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faCheck} className={validPassword ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "hide" : "invalid"} />
                    <IconButton onClick={handleEye}>
                      {eye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              className={password && !validPassword ? "instructions" : "offscreen"}
              align="left"
              sx={{
                mt: -0.3,
                fontWeight: 500,
                fontSize: 12,
                width: '100%'
              }}>
              <FontAwesomeIcon icon={faInfoCircle} />
              12 to 24 characters.<br />
              Must include uppercase and lowercase letters, a number and a special character.<br />
              Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
            </Typography>
          </Grid>

          <Grid item xs={12} >
            <TextField
              required
              type={matchEye ? "text" : "password"}
              label="Confirm Password"
              variant="outlined"
              size="small"
              name="matchPassword"
              value={matchPassword || ''}
              sx={{
                width: '100%'
              }}
              onChange={(e) => setMatchPassword(e.target.value)}
              error={!!matchPassword && !validMatch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faCheck} className={validMatch && matchPassword ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={!validMatch && matchPassword ? "invalid" : "hide"} />
                    <IconButton onClick={handleMatchEye}>
                      {matchEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} >

            <Typography
              className={matchPassword && !validMatch ? "instructions" : "offscreen"}
              align="left"
              sx={{
                mt: -0.3,
                fontWeight: 500,
                fontSize: 12,
                width: '100%'
              }}>
              <FontAwesomeIcon icon={faInfoCircle} />
              Must match the first password input field.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.middle}>
            <Button
              type='submit'
              variant="contained"
              sx={{
                width: '60%'
              }}>
              Create and Log In
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                width: '100%',
              }}
            >
              By creating a password, you are agreeing to our Terms of Service
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ResetPassword;

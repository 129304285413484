import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import SubscribeForm from './SubscribeFormBackend';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscribeContainer = ({ price, priceAnnual, interval, setInterval,
    defaultPaymentMethod, buttonText, updateButtonText }) => {
    return (
        <Elements stripe={stripeTestPromise}>
            <SubscribeForm
                price={price}
                priceAnnual={priceAnnual}
                interval={interval}
                setInterval={setInterval}
                defaultPaymentMethod={defaultPaymentMethod}
                buttonText={buttonText}
                updateButtonText={updateButtonText}
            />
        </Elements>

    )
}

export default SubscribeContainer;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Box, Typography, Divider, Paper } from '@mui/material';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { BillingService } from '../../services/BillingService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { makeStyles } from '@mui/styles';
import CardInput from './CardInput';
import { useCard } from './useCard';
import { useStyles as useLocalStyles } from './styles';
import { useBaseStyles } from '../../styles';
import { Button } from "../common/inputs";
import { Notification } from '../common';
import { capitalize } from '../../utils/string';
import notifyError from '../../errors/notifyError';

import { RadioButton, ButtonGroup } from '../common/inputs';
import { disabledRoles } from '../../data';
import useAuth from '../../hooks/useAppContext';

const useStyles = makeStyles(theme => ({
    paper: {
        textAlign: 'left',
        padding: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 330
    },
    subscriptionCard: {
        border: '2px solid blue',
        borderRadius: 5,
        margin: 8,
        padding: 8
    },
}));

export default function SubscribeForm({ price, priceAnnual, interval, setInterval,
    defaultPaymentMethod, buttonText, updateButtonText }) {
    const classes = useStyles();
    const localClasses = useLocalStyles();
    const baseClasses = useBaseStyles();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const stripe = useStripe();
    const elements = useElements();
    const [width, setWidth] = useState(window.innerWidth);
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [loading, setLoading] = useState(false);

    const {
        values,
        setValues,
        errors,
        handleInputChange,
        resetForm,
        validate
    } = useCard();

    useEffect(() => {
        window.onresize = function () {
            setWidth(window.innerWidth);
        }
    }, []);

    useEffect(() => {
        updateButtonText(price, priceAnnual);
    }, [interval]);


    const handleError = (error) => {
        setLoading(false);
        setNotify({
            isOpen: true,
            message: error.message,
            type: 'error'
        })
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        if (!price) {
            handleError({ message: 'Please select a plan' });
            return;
        }

        try {
            let res;
            let priceId = price.id;
            if (interval === 'year') {
                priceId = priceAnnual.id;
            }

            if (!defaultPaymentMethod) {
                if (!validate()) {
                    return;
                }

                const { id, name, email, ...rest } = values;
                const billing_details = {
                    name,
                    email,
                    address: rest
                }
                // Trigger form validation and wallet collection
                const { error: submitError } = await elements.submit();
                if (submitError) {
                    handleError(submitError);
                    return;
                }

                const result = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                    billing_details
                });

                if (result.error) {
                    handleError(result.error);
                    return;
                }

                res = await BillingService.subscribeBackend(axiosPrivate,
                    {
                        priceId,
                        'payment_method': result.paymentMethod.id,
                        email
                    });
            } else {
                res = await BillingService.subscribeBackend(axiosPrivate,
                    {
                        priceId,
                        email: defaultPaymentMethod.billing_details.email
                    });
            }

            if (buttonText === "Subscribe") {
                // eslint-disable-next-line camelcase
                const { client_secret, status } = res.data;

                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(function (result) {
                        if (result.error) {
                            handleError(result.error);
                            // Display error message in your UI.
                            // The card was declined (i.e. insufficient funds, card has expired, etc)
                        } else {
                            console.log('You got the money!');
                            // Show a success message to your customer
                        }
                    });
                } else {
                    console.log('You got the money!');
                    // No additional information was needed
                    // Show a success message to your customer
                    navigate('/settings/billing', { replace: true });
                }
            } else {
                const { msg } = res.data;
                console.log(msg);
                navigate('/settings/billing', { replace: true });
            }
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={4}>

                        {/* <Grid item xs={12} lg={6} >
                        <Typography sx={{ fontSize: 20 }}>
                            Not sure what you're getting?
                        </Typography>

                        <Typography sx={{ fontSize: 16 }}>
                            Select a plan above and see the features included
                            {'\n'}
                            Click on any feature to learn more
                        </Typography>

                    </Grid> */}

                        <Grid container item xs={12} lg={6}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 20 }}>
                                    Selected: {price.metadata.plan}
                                </Typography>
                            </Grid>

                            <ButtonGroup value={interval} setValue={setInterval}>
                                <Grid container spacing={2}>
                                    <Grid item xs={width > 480 ? 0 : 2}>
                                        <Typography variant='h4' sx={{ color: 'white' }}>

                                        </Typography>
                                    </Grid>
                                    <Grid item xs={width > 480 ? 5 : 10} direction='column'>
                                        <RadioButton label="Monthly" value="month" >
                                            <Typography sx={{ fontSize: 13 }}>
                                                PAY MONTHLY
                                            </Typography>

                                            <Typography variant='h4'>
                                                ${price.unit_amount / 100}
                                            </Typography>

                                            <Typography sx={{ fontSize: 13 }}>
                                                PER MONTH
                                            </Typography>
                                        </RadioButton>
                                    </Grid>

                                    <Grid item xs={width > 480 ? 1 : 2}>
                                        <Typography variant='h4' sx={{ color: 'white' }}>

                                        </Typography>
                                    </Grid>


                                    <Grid item xs={width > 480 ? 5 : 10} direction='column'>
                                        <RadioButton label="Annually" value="year" >
                                            <Typography sx={{ fontSize: 13 }}>
                                                PAY ANNUALLY
                                            </Typography>
                                            <Typography variant='h4'>
                                                ${(priceAnnual.unit_amount / 12 / 100).toFixed(2)}
                                            </Typography>
                                            <Typography sx={{ fontSize: 13 }}>
                                                PER MONTH
                                            </Typography>
                                        </RadioButton>
                                    </Grid>
                                </Grid>
                            </ButtonGroup>

                            <Grid item xs={12} >
                                <Divider sx={{ my: 1 }} />
                                <Typography sx={{ fontSize: 11 }}>
                                    Our flexible plans allow you to change or cancel at any time. If you cancel, you will receive a prorated refund. And if you have questions at any time, our support team is here to help you.
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                            </Grid>

                            <Grid item xs={6} >
                            </Grid>

                            <Grid container item xs={6} className={baseClasses.secondary} spacing={2} sx={{ pr: 2 }}>
                                <Grid item xs={6} >
                                    <Typography sx={{ fontSize: 12 }}>
                                        TOTAL MONTHLY
                                    </Typography >

                                    {interval === 'year' &&
                                        <Typography sx={{ fontSize: 12, pt: 1 }}>
                                            PER YEAR
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item xs={6} className={baseClasses.right}>
                                    <Typography sx={{ fontSize: 16 }}>
                                        ${interval === 'month' ? price.unit_amount / 100 : (priceAnnual.unit_amount / 12 / 100).toFixed(2)}
                                    </Typography>

                                    {interval === 'year' &&
                                        <Typography sx={{ fontSize: 16 }}>
                                            x 12
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>


                            {interval === 'year' &&
                                <>
                                    <Grid item xs={6} >

                                    </Grid>
                                    <Grid container item xs={6} sx={{ pr: 2 }} spacing={2} className={baseClasses.secondary}>
                                        <Grid item xs={6}>
                                            <Divider sx={{ my: 0.5 }} />
                                            <Typography sx={{ fontSize: 12, pt: 0.5 }}>
                                                TOTAL ANNUALLY
                                            </Typography >
                                        </Grid>

                                        <Grid item xs={6} className={baseClasses.right}>
                                            <Divider sx={{ my: 0.5 }} />
                                            <Typography sx={{ fontSize: 16 }}>
                                                ${priceAnnual.unit_amount / 100}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>

                        <Grid container item xs={12} lg={6} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" >
                                    Payment
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                            </Grid>


                            {defaultPaymentMethod ?
                                <Grid container item xs={12} md={6} direction='column' spacing={2} >
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        className={classes.subscriptionCard}
                                    >
                                        <Typography variant="button" className={`${baseClasses.base} ${localClasses.defaultCard}`}
                                            sx={{ textTransform: 'capitalize', width: 70 }}
                                        >
                                            Default
                                        </Typography>

                                        <Typography >
                                            {capitalize(defaultPaymentMethod.card.brand)}....{defaultPaymentMethod.card.last4} {'\n'}
                                            {'Expires'} {defaultPaymentMethod.card.exp_month} {'/'} {defaultPaymentMethod.card.exp_year} {'\n'}
                                            {defaultPaymentMethod.billing_details.address.line1} {'\n'}
                                            {defaultPaymentMethod.billing_details.address.city} {' '}
                                            {defaultPaymentMethod.billing_details.address.state} {' '}
                                            {defaultPaymentMethod.billing_details.address.postal_code}
                                            {'\n'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <CardInput
                                    values={values}
                                    setValues={setValues}
                                    errors={errors}
                                    handleInputChange={handleInputChange}
                                />
                            }

                            <Grid item xs={12} className={baseClasses.right} sx={{ color: 'white' }}>
                                <Divider sx={{ my: 2 }} />
                                <Button
                                    type="submit"
                                    disabled={!stripe || loading || buttonText === "Purchase" || disabledRoles.includes(auth.user.role)}
                                    size="medium"
                                    color="brightRed"
                                    text={buttonText}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </form >
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    );
}
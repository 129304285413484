const Platforms = [
    {
        id: 1,
        label: 'BigCommerce'
    },
    {
        id: 2,
        label: 'Shopify'
    }
];

export default Platforms;
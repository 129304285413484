const StoreUserService = {
    getUsers: function (axiosPrivate) {
        //call API: HTTP method (GET) + URL (http://34.213.41.170:8080/courses)
        return axiosPrivate.get('/api/v1/store_users');
    },
    addUser(axiosPrivate, storeUser, storeName) {
        return axiosPrivate.post('/api/v1/store_users', { ...storeUser, storeName });
    },
    editUser(axiosPrivate, storeUser) {
        return axiosPrivate.put('/api/v1/store_users', storeUser);
    },
    removeUser(axiosPrivate, storeUserId) {
        return axiosPrivate.delete(`/api/v1/store_users/${storeUserId}`);
    }
}

export default StoreUserService;
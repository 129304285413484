import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Grid, Typography, ButtonBase, Divider } from '@mui/material';
import { computeMonthlyPriceBilledAnnually } from './BillingUtil';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paragraph: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        textAlign: 'center',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: 260,
        width: 330,
        '&:hover': {
            backgroundColor: '#4d4dff',
        },
    }
}));


const Plans = ({ prices, pricesAnnual, plan, currentPlan, handleOtherClick, handleClick }) => {
    const classes = useStyles();
    const blue = '#4d4dff';
    const grey = '#d3d3d3';
    // ref value will persist across re-renders
    let buttonGroupRef = useRef();
    let prevCheckedButtonRef = useRef();

    useEffect(() => {
        // plan is the dependancy for this useEffect, whenever it changes, 
        // removing attribute from the prevPlan 
        // setting the attribute for checked Plan and 

        let checkedPlan = buttonGroupRef.current.querySelector(`#${plan}`);

        if (prevCheckedButtonRef.current && checkedPlan !== prevCheckedButtonRef.current) {
            prevCheckedButtonRef.current.style.color = 'black';
            prevCheckedButtonRef.current.style.backgroundColor = 'white';
        }

        checkedPlan.style.backgroundColor = blue;
        checkedPlan.style.color = 'white';

        prevCheckedButtonRef.current = checkedPlan;
    }, [plan]);


    return (
        <Grid container ref={buttonGroupRef} alignItems="stretch" justify='space-around' spacing={2} sx={{ my: 1 }}>
            <Grid item xs={12} sm={6} md={4} xl={3} className={classes.root}>
                <ButtonBase onClick={(e) => handleOtherClick(e, "Free")}>
                    <Paper className={classes.paper} id="Free">
                        <Typography varaint="h5" sx={{ fontSize: 20 }} className={classes.paragraph}>
                            Free
                            {currentPlan === 'Free' &&
                                <span style={{ color: grey }}>  [Current Plan]
                                </span>
                            }
                        </Typography>

                        <Divider />
                        <Typography className={classes.paragraph}>
                            &#8804; 100 orders
                        </Typography>
                    </Paper>
                </ButtonBase>
            </Grid>

            {prices.map((priceItem) => {
                return (
                    <Grid item key={priceItem.id} xs={12} sm={6} md={4} xl={3} className={classes.root}>
                        <ButtonBase onClick={(e) => handleClick(e, priceItem)}>
                            <Paper className={classes.paper} id={priceItem.metadata.plan}>
                                <Typography sx={{ fontSize: 20 }} className={classes.paragraph} >
                                    {priceItem.metadata.plan}
                                    {currentPlan === priceItem.metadata.plan &&
                                        <span style={{ color: grey }}>  [Current Plan]
                                        </span>
                                    }
                                </Typography>
                                {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-end" }}>  cannot display in the middle */}
                                <div>
                                    <Typography variant='h4' sx={{ display: 'inline-block' }} >
                                        ${computeMonthlyPriceBilledAnnually(priceItem, pricesAnnual)}
                                    </Typography>
                                    <span sx={{ fontSize: 16, fontWeight: 'light' }}> /month</span>
                                </div>
                                {/* </Box> */}
                                <div className={classes.paragraph}>
                                    <Typography sx={{ fontSize: 16 }} >
                                        billed annually
                                    </Typography>

                                    <Typography sx={{ fontSize: 16, fontWeight: 600 }} >
                                        ${priceItem.unit_amount / 100} {" billed monthly"}
                                    </Typography>
                                </div>

                                <Divider />

                                <Typography className={classes.paragraph}>
                                    &#8804; {" "}
                                    {priceItem.unit_amount === 499 ? 300 :
                                        priceItem.unit_amount === 1499 ? 1000 : 5000}
                                    {" orders"}
                                </Typography>

                            </Paper>
                        </ButtonBase>
                    </Grid>
                )
            })}

            <Grid item xs={12} sm={6} md={4} xl={3} className={classes.root}>
                <ButtonBase onClick={(e) => handleOtherClick(e, "Enterprise")}>
                    <Paper className={classes.paper} id="Enterprise">
                        <Typography varaint="h5" sx={{ fontSize: 20 }} className={classes.paragraph}>
                            Enterprise
                            {currentPlan === 'Enterprise' &&
                                <span style={{ color: grey }}>  [Current Plan]
                                </span>
                            }
                        </Typography>
                        <Divider />
                        <div className={classes.paragraph} >
                            <Typography >
                                &gt; 5000 orders
                            </Typography>
                            <Typography>
                                Contact Us
                            </Typography>
                        </div>
                    </Paper>
                </ButtonBase>
            </Grid>
        </Grid>
    );
}

export default React.memo(Plans);

import React from 'react';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import RuleIcon from '@mui/icons-material/Rule';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import { Email, Contacts, NetworkPing, Phone } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const menu = [
    {
        title: 'Orders',
        icon: <InboxIcon />,
        children: [
            {
                title: 'All',
                path: '/orders',
                icon: <ShoppingCartIcon />
            },
            {
                title: 'Fraud',
                path: '/orders/fraud',
                icon: <WarningIcon />
            },
            {
                title: 'Flagged',
                path: '/orders/flagged',
                icon: <DangerousIcon />
            },
        ]
    },
    {
        title: 'RedFlag Rules',
        icon: <RuleIcon />,
        children: [
            {
                title: 'Email',
                path: '/rules/email',
                icon: <Email />
            },
            {
                title: 'Address',
                path: '/rules/address',
                icon: <Contacts />
            },
            {
                title: 'IP',
                path: '/rules/ip',
                icon: <NetworkPing />
            },
            {
                title: 'Phone',
                path: '/rules/phone',
                icon: <Phone />
            },
        ]
    },
    {
        title: 'Settings',
        icon: <SettingsIcon />,
        children: [
            {
                title: 'General',
                icon: <SettingsIcon />,
                path: '/settings/general'
            },
            {
                title: 'Manage Users',
                icon: <GroupIcon />,
                path: '/settings/users',
            },
            {
                title: 'Billing',
                icon: <AttachMoneyIcon />,
                path: '/settings/billing',
            }
        ]
    },
    {
        title: 'Profile',
        icon: <AccountCircleIcon />,
        path: '/profile',
    },
    {
        title: 'Contact',
        icon: <ContactSupportIcon />,
        path: '/contact',
    },
    {
        title: 'Logout',
        icon: <LogoutIcon />,
        path: '/logout',
    }
]

export default menu;
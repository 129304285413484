import axios from "../axios";

export const AuthService = {
    login: function (email, password) {
        return axios.post("/api/v1/auth/login", { email, password });
    },
    logout: function () {
        return axios.delete("/api/v1/auth/logout", {
            withCredentials: true
        });
    },
    register: function (company, addr, contact, password) {
        return axios.post("/api/v1/auth/register", { ...company, addr, contact, password });
    },

    verifyEmail: function (token, email) {
        return axios.post('/api/v1/auth/verify_email', { token, email });
    },

    verifyDeviceOtp: function ({ email, otp }) {
        return axios.post('/api/v1/auth/verify_device_otp', { otp, email });
    },

    resendOtp: function ({ email, isDevice }) {
        return axios.post('/api/v1/auth/resend_otp', { email, isDevice });
    },

    storeLogin: function ({ email, refreshToken, storeId }) {
        return axios.post("/api/v1/auth/storeLogin", { email, refreshToken, storeId });
    },

    forgotPassword: function (email) {
        return axios.post('/api/v1/auth/forgot_password', { email });
    },

    resetPasswordAllInOne: function ({ email, otp, password }) {
        return axios.put('/api/v1/auth/password', { email, otp, password });
    },

    forgotPasswordV1: function (email) {
        return axios.get(`/api/v1/auth/forgot_password/${email}`);
    },

    resetPassword: function (password, token, email) {
        return axios.put('/api/v1/auth/reset_password', {
            password,
            token,
            email
        });
    },

    createPasswordAndLogin: function (password, token, email) {
        return axios.post('/api/v1/auth/create_password_login', {
            password,
            token,
            email
        });
    },

    adminLogin: function (email, password) {
        return axios.post("/api/v1/auth/admin_login", { email, password });
    },

    adminRegister: function (contact, password) {
        return axios.post("/api/v1/auth/admin_register", { contact, password });
    },

    adminVerifyEmail: function (token, email) {
        return axios.post('/api/v1/auth/admin_verify_email', { token, email });
    },

    adminVerifyDeviceOtp: function ({ email, otp }) {
        return axios.post('/api/v1/auth/admin_verify_device_otp', { otp, email });
    },

    adminResendOtp: function ({ email, isDevice }) {
        return axios.post('/api/v1/auth/admin_resend_otp', { email, isDevice });
    },

    adminForgotPassword: function (email) {
        return axios.post('/api/v1/auth/admin_forgot_password', { email });
    },

    adminResetPasswordAllInOne: function ({ email, otp, password }) {
        return axios.put('/api/v1/auth/admin_password', { email, otp, password });
    },

}

import axios from "../axios";

export const TicketService = {
    submitTicket(ticket) {
        return axios.post(`/api/v1/tickets`, ticket);
    },
    getTickets() {
        return axios.get(`/api/v1/tickets`);
    }
}

import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Paper } from '@mui/material';
import { TicketService } from '../services/TicketService';
import displayError from '../errors/displayError';
import { PageHeader } from '../components/common';

import { useStyles } from './styles';
import { useBaseStyles } from '../styles';

function Contact() {
    const classes = useStyles();
    const baseClasses = useBaseStyles();
    const [contact, setContact] = useState({ firstName: '', lastName: '', email: '' });
    const [question, setQuestion] = useState();

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const handleContactChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setContact({ ...contact, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // if button enabled with JS hack
        const { firstName, lastName, email } = contact;
        const name = `${firstName} ${lastName}`;

        TicketService.submitTicket({ name, email, question })
            .then(response => {
                setSuccess(true);
            })
            .catch(err => {
                displayError(err, setErrMsg);
            });
    };

    return (
        <>
            <PageHeader
                title="Contact"
            />
            <Paper className={classes.pageContent}>
                {
                    success ?
                        <h1 className={"alert-success register-container"}>Thank you, we will reply to you as soon as possible</h1>
                        :
                        <>
                            <p className={errMsg ? "alert-danger register-container" : "offscreen"}>{errMsg}</p>
                            <form className="register-container" onSubmit={(e) => handleSubmit(e)}>
                                <Grid container spacing={2}>

                                    {/* contact information*/}
                                    <br />
                                    <Grid item xs={12}>
                                        <Typography sx={{ pl: 1, pt: 1, fontWeight: 550, fontSize: 14, textAlign: 'left' }}>
                                            Personal Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type='text'
                                            label="First name"
                                            variant="outlined"
                                            size="small"
                                            name='firstName'
                                            value={contact.firstName || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type='text'
                                            label="Last name"
                                            variant="outlined"
                                            size="small"
                                            name='lastName'
                                            value={contact.lastName || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            type='email'
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            name="email"
                                            value={contact.email || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>
                                    <br />
                                    <Grid item xs={12}>
                                        <Typography sx={{ pl: 1, pt: 1, fontWeight: 550, fontSize: 14, textAlign: 'left' }}>
                                            Question
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            placeholder="Please type your question"
                                            multiline
                                            name="question"
                                            value={question}
                                            rows={5}
                                            maxRows={10}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={e => setQuestion(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} className={baseClasses.middle}>
                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{
                                                width: '40%'
                                            }}>
                                            Contact
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                }
            </Paper>
        </>
    );
}

export default Contact;

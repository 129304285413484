export const RedFlagService = {
    getEmails(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/redflags/emails`);
    },
    //point: set email in the request data (body)
    addEmail(axiosPrivate, email) {
        return axiosPrivate.post(`/api/v1/redflags/emails`, { email });
    },

    //point: delete request does not have body entity because it is ignored by express
    removeEmail(axiosPrivate, emailId) {
        return axiosPrivate.delete(`/api/v1/redflags/emails/${emailId}`);
    },

    getIps(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/redflags/ips`);
    },
    addIp(axiosPrivate, ip) {
        return axiosPrivate.post(`/api/v1/redflags/ips`, { ip });
    },
    removeIp(axiosPrivate, ipId) {
        return axiosPrivate.delete(`/api/v1/redflags/ips/${ipId}`);
    },

    getAddrs(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/redflags/addrs`);
    },
    addAddr(axiosPrivate, addr) {
        return axiosPrivate.post(`/api/v1/redflags/addrs`, { addr });
    },
    updateAddr(axiosPrivate, newAddr) {
        return axiosPrivate.put(`/api/v1/redflags/addrs`, { newAddr });
    },
    removeAddr(axiosPrivate, addrId) {
        return axiosPrivate.delete(`/api/v1/redflags/addrs/${addrId}`);
    },

    getPhones(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/redflags/phones`);
    },
    addPhone(axiosPrivate, phone) {
        return axiosPrivate.post(`/api/v1/redflags/phones`, { phone });
    },
    removePhone(axiosPrivate, phoneId) {
        return axiosPrivate.delete(`/api/v1/redflags/phones/${phoneId}`);
    },

}

//java static class
import React, { createContext, useState } from 'react';

const AppContext = createContext({});

// avoid the same name of hooks auth and store
const { auth: userAndToken, store: Store } = window.__user || {};

export const AppProvider = ({ children }) => {
    const [auth, setAuth] = useState(userAndToken ? userAndToken : null);
    const [stores, setStores] = useState([]);
    const [store, setStore] = useState(Store ? Store : null);

    return (
        <AppContext.Provider value={{ auth, setAuth, stores, setStores, store, setStore }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;
import React, { useState, useEffect } from 'react';
import { CardContainer } from "../../components/stripe";
import { Paper, Box, TableBody, TableRow, TableCell, Grid, TextField, MenuItem, Typography } from '@mui/material';
import { Button, ActionButton } from "../../components/common/inputs";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAppContext';
import { BillingService } from "../../services/BillingService";
import { useTable, Popup, PageHeader, Notification, ConfirmDialog } from '../../components/common';
import notifyError from '../../errors/notifyError';
import { useStyles } from '../../components/stripe/styles';
import { DispayCardBrandLogo } from '../../components/stripe/BillingUtil';
import { capitalize } from '../../utils/string';
import { disabledRoles } from '../../data';

const headCells = [
    { id: 'brand', label: 'Brand' },
    { id: 'card', label: 'Card' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]

export default function Cards() {
    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [records, setRecords] = useState([]);
    const filterFn = { fn: items => { return items; } };
    const [openPopup, setOpenPopup] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState('');

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        BillingService.getPaymentMethods(axiosPrivate)
            .then(response => {
                const { paymentMethods, defaultPaymentMethodId } = response.data;
                isMounted && setRecords(paymentMethods);
                isMounted && setDefaultPaymentMethodId(defaultPaymentMethodId);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate]);

    const {
        TblContainer,
        TblHead
    } = useTable(records, headCells, filterFn);


    const addOrEdit = async (paymentMethodId, isDefault, resetForm) => {
        try {
            // if (card.id === 0) {

            const { data: { paymentMethod } } = await BillingService.finishSetupPaymentMethod(axiosPrivate, {
                'payment_method': paymentMethodId,
                isDefault
            });

            setRecords([...records, paymentMethod]);
            if (defaultPaymentMethodId === '' || isDefault) {
                setDefaultPaymentMethodId(paymentMethodId);
            }
            // }
            // else {
            //     await StoreUserService.editUser(axiosPrivate, card)
            //     const newRecords = [...records];
            //     let recordIndex = newRecords.findIndex(x => x.id === card.id);
            //     newRecords[recordIndex] = { ...card }
            //     setRecords([...newRecords]);
            // }

            resetForm()
            setRecordForEdit(null)
            setOpenPopup(false)

            setNotify({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }
    }


    const handleDelete = async (paymentId) => {
        try {
            await BillingService.removePaymentMethod(axiosPrivate, { 'payment_method': paymentId });

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            const newRecords = records.filter((item) => item.id !== paymentId);
            setRecords(newRecords);
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }
    }

    const handleDefaultChange = async (e, paymentId, email) => {
        try {
            await BillingService.setDefaultPaymentMethod(axiosPrivate, { 'payment_method': paymentId, email })
            setDefaultPaymentMethodId(paymentId);
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }
    }

    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    return (
        <>
            <PageHeader
                title="Settings"
                subTitle="Payment Methods"
            />

            <Paper className={classes.pageContent}>

                <Box sx={{
                    minWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Grid container>
                        <Grid item xs={12} className={classes.right}>
                            <Button
                                disabled={disabledRoles.includes(auth.user.role)}
                                text="Add New Card"
                                variant="outlined"
                                startIcon={<AddIcon />}
                                onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                            />
                        </Grid>
                    </Grid>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                records.map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>
                                        <DispayCardBrandLogo brand={item.card.brand} />
                                    </TableCell>
                                    <TableCell>
                                        {capitalize(item.card.brand)}....{item.card.last4} {'\n'}
                                        {'Expires'} {item.card.exp_month} {'/'} {item.card.exp_year}
                                    </TableCell>
                                    <TableCell>
                                        {item.id === defaultPaymentMethodId ?
                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                Default
                                            </Typography> :
                                            <TextField
                                                disabled={disabledRoles.includes(auth.user.role)}
                                                variant="standard"
                                                size="small"
                                                name="defaultValue"
                                                value={'None'}
                                                onChange={(e) => handleDefaultChange(e, item.id, item.billing_details.email)}
                                                select
                                            >
                                                <MenuItem key='None' value={'None'}>None</MenuItem>
                                                <MenuItem key='Default' value={'Default'}>Default</MenuItem>
                                            </TextField>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {/* <ActionButton
                                        color="primary"
                                        disabled={disabledRoles.includes(auth.user.role)}
                                        onClick={() => { openInPopup(item) }}>
                                        <EditOutlinedIcon fontSize="small" />
                                    </ActionButton> */}
                                        <ActionButton
                                            color="secondary"
                                            disabled={disabledRoles.includes(auth.user.role) || item.id === defaultPaymentMethodId}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Are you sure to delete this record?',
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => handleDelete(item.id)
                                                })
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </ActionButton>
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                </Box>
            </Paper>
            <Popup
                title="Card Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <CardContainer
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
import { AuthService } from '../services/AuthService';
import { clearAll } from '../browser/localStorage';
import { usePostMessage } from './useBroadcast';

const useLogout = () => {
    const postMessage = usePostMessage();

    const logout = async () => {
        try {
            clearAll();
            const response = await AuthService.logout();
            postMessage();
            if (response.status === 200) {
                console.log(response.data.msg);
            }
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, TextField, Box, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SearchButton } from "../common/components/common/inputs";

export default function SearchBar() {
    const [text, setText] = useState(''); //  the phrase to be searched
    let searchedMillis = 0; // the time that just has searched some orders
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    let regex = /\D/;
    const [zipVisibility, setZipVisibility] = useState("hidden");

    const StyledButton = styled(SearchButton)(({ theme }) => ({
    }))

    const handleChange = (e) => {
        const value = e.target.value;
        let nonDigits = regex.test(value);
        if (nonDigits) {
            setZipVisibility("hidden");
            setChecked(false);
        } else { // all digits
            setZipVisibility("visible");
        }
        setText(value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }

    const handleCheckedChange = (e) => {
        setChecked(e.target.checked);
    };

    const handleSearch = (e) => {
        if (text === '')
            return;

        if (text.includes('@')) {
            navigate(`/orders?email=${text}`)
        } else {
            if (checked)
                navigate(`/orders?zip=${text}`)
            else {
                navigate(`/orders?id=${text}`)
            }
        }

    }


    const handleClear = () => {
        setText("");
        navigate(`/orders`)
    };

    return (
        <Box sx={{
            display: "flex",
            marginLeft: 7
        }}>
            <TextField
                type='text'
                size="small"
                variant='outlined'
                name="text"
                value={text || ''}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Order NO, zip code or email"
                sx={{
                    minWidth: 600,
                    bgcolor: 'white',
                    borderRadius: 1
                }}

                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FormControlLabel
                                sx={{
                                    visibility: text ? zipVisibility : "hidden",
                                    margin: 0
                                }}
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleCheckedChange}
                                    />}
                                label="Zip?" />
                            <IconButton
                                sx={{
                                    visibility: text ? "visible" : "hidden",
                                    margin: 0
                                }}
                                onClick={handleClear}
                            >
                                <ClearIcon />
                            </IconButton>

                            <StyledButton
                                //following can override only in styled indirectly instead of in SearchBar directly
                                sx={{
                                    marginRight: -1.6
                                }}
                                text="Search"
                                color="grey"
                                size="medium"
                                onClick={handleSearch} />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}
import { clearLogin, clearSearch } from '../browser/localStorage';

// login users
// if access token is expired, 
// 1, receive 403 "Authorization Invalid" error
// 2, useAxiosPrivate intercepts this error, and refreshs the access token by refresh token
// and will receive "Authentication Invalid" error if refresh token expires
// The "Authentication Invalid" error should direct the front-end to the  login page

// Need to clear auth by setAuth({}) because Login does load app inside Bigcommerce 
// based on auth.user and auth.accessToken

// the useAuth hook can only be called by other hooks or at the top level of React Component
// ordinary function cannot call the useAuth hook, 
// so the setAuth({}) is called in the useAxiosPrivate when refresh token is expired
function displayError(err, setErrMsg, navigate, location) {
    console.log(err.response?.data?.msg, location);
    let message;
    if (!err.response?.data?.msg) {
        message = 'No Server Response';
    } else if (err.response.status === 403) {
        if (err.response.data.msg) {
            message = err.response.data.msg;
        } else {
            message = 'Forbidden Access to this route';
        }
    } else if (err.response.status === 500) {
        message = 'Internal Server Error: ' + err.response.data.msg;
    }
    else {
        message = err.response.data.msg;
    }

    if (message === "Authentication Invalid" || message === "Unauthorized to refresh token") {
        clearLogin();
        clearSearch();
        navigate('/login', { state: { from: location }, replace: true });
    }

    setErrMsg(message);
}

export default displayError;
import React from 'react';
import OrderDisplay from '../../components/orders/OrderDisplay';
import OrderService from '../../services/OrderService';

const FraudOrders = () => {
    return (
        <OrderDisplay ordersFunct={OrderService.getFraudOrders} />
    );
}

export default FraudOrders;
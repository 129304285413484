import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthService } from '../../services/AuthService';
import useQuery from '../../hooks/useQuery';


const VerifyEmail = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { isLoading } = useGlobalContext();
  const query = useQuery();


  useEffect(() => {

    const verifyToken = async () => {
      setLoading(true);

      AuthService.verifyEmail(query.get('token'), query.get('email'))
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.log(error.response);
          setError(true);
        });

      setLoading(false);
    };

    // if (!isLoading) {
    verifyToken();
    // }
  }, [query]);

  if (loading) {
    return (
      <Wrapper className='page'>
        <h2>Loading...</h2>
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper className='page'>
        <h4>There was an error, please double check your verification link </h4>
      </Wrapper>
    );
  }

  return (
    <Wrapper className='page'>
      <h2>Account Confirmed</h2>
      <Link to='/login' className='btn'>
        Please login
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.section``;

export default VerifyEmail;

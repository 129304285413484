import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pageContent: {
        margin: theme.spacing(8),
        padding: theme.spacing(3),
        textAlign: 'left',
        width: 800
    },
}));


import React, { useState, useEffect } from 'react';
import { TextInput } from '../common/inputs';
import { FormControl, MenuItem, Grid } from '@mui/material';
import { CanadaStates, USAbbrevStates, ZipPatterns, Countries } from '../../data';
import { CountryAbbrev } from '../common';
import { CardElement } from '@stripe/react-stripe-js';
import { grey, blue } from '@mui/material/colors';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            'color': '#32325d',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '16px',
            '::placeholder': {
                color: '#aab7c4',
            },

        },
        //cannot define the border attributes
        // focus: {
        //     'border': 1,
        //     'borderRadius': '4px',
        //     'borderColor': grey[400],
        //     'padding': 1.2,
        // },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};


export default function CardInput(props) {
    const {
        recordForEdit,
        values,
        setValues,
        errors,
        handleInputChange,
    } = props;

    const [country, setCountry] = useState(Countries[230]); // displayed in the input
    const [zipPattern, setZipPattern] = useState({ placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" });
    const [zipDisplay, setZipDisplay] = useState({ disabled: false, backgroundColor: 'white' });
    const [states, setStates] = useState(USAbbrevStates);
    const [isUSCanada, setIsUSCanada] = useState(true);

    let cardFocused = false;

    useEffect(() => {
        if (recordForEdit) {
            const foundCountry = Countries.find(country => country.code === values.country);
            setCountry(foundCountry); // displayed in the input
            checkStateZipPatternChanges(foundCountry.code);
        }
    }, []);

    const handleCountryChange = (newCountry) => {
        const country = newCountry;
        setValues({ ...values, country });
        checkStateZipPatternChanges(country);
    };

    const checkStateZipPatternChanges = (country) => {
        if (country === 'US') {
            setStates(USAbbrevStates);
            setIsUSCanada(true);
        } else if (country === 'CA') {
            setStates(CanadaStates);
            setIsUSCanada(true);
        } else {
            setIsUSCanada(false);
        }
        const zipCode = ZipPatterns.get(country);
        setZipPattern(zipCode);
        if (zipCode.pattern === '') {
            setZipDisplay({ disabled: true, backgroundColor: '#D3D3D3' });
        } else {
            setZipDisplay({ disabled: false, backgroundColor: 'white' });
        }
    }

    const handleCardFocus = (e) => {
        cardFocused = true;
        const formControl = document.querySelector('#formControl');
        formControl.style.borderWidth = '2px';
        formControl.style.borderColor = blue[700];
    }

    const handleCardBlur = (e) => {
        cardFocused = false;
        const formControl = document.querySelector('#formControl');
        formControl.style.borderWidth = '1px';
        formControl.style.borderColor = grey[400];
    }

    const handleCardOver = (e) => {
        if (cardFocused === false) {
            const formControl = document.querySelector('#formControl');
            formControl.style.borderWidth = '1px';
            formControl.style.borderColor = 'black';
        }
    }

    const handleCardOut = (e) => {
        if (cardFocused === false) {
            const formControl = document.querySelector('#formControl');
            formControl.style.borderWidth = '1px';
            formControl.style.borderColor = grey[400];
        }
    }

    return (
        <>
            <Grid item xs={6}>
                <TextInput
                    type="text"
                    label="Card Holder Name"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    sx={{
                        width: '100%'
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextInput
                    type="email"
                    label="Receipt Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    sx={{
                        width: '100%'
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                {/* move the effects of hover and focus events on the FormControl ouside of the CardElement
                        may also work on the CardElement by setting CSS for the '.StripeElement' inside CardElement*/}
                <FormControl
                    tabIndex="0"
                    id='formControl'
                    size="small"
                    variant='outlined'
                    sx={{
                        width: '100%',

                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '4px',
                        borderColor: grey[400],
                        padding: 1.2,

                        //conflict with focus event
                        '&:hover': {
                            borderWidth: '1px',
                            borderColor: 'black',
                        },

                        //focus event does not bubble
                    }}
                    onMouseOver={handleCardOver}
                    onMouseOut={handleCardOut}
                >
                    <CardElement options={CARD_ELEMENT_OPTIONS}
                        onFocus={handleCardFocus}
                        onBlur={handleCardBlur}
                    />
                </FormControl>
            </Grid>

            <br />

            {/* billing address */}
            <Grid item xs={12}>
                <TextInput
                    type='text'
                    label="Street Address"
                    name="line1"
                    value={values.line1 || ''}
                    placeholder="Street Address or P.O. Box"
                    onChange={handleInputChange}
                    error={errors.line1}
                    sx={{
                        width: '100%'
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    type='text'
                    label="City"
                    name="city"
                    value={values.city || ''}
                    onChange={handleInputChange}
                    error={errors.city}
                    sx={{
                        width: '100%'
                    }}
                />
            </Grid>


            <Grid item xs={6} >
                {isUSCanada ?
                    <TextInput
                        label="State"
                        name="state"
                        value={values.state || ''}
                        placeholder="State"
                        onChange={handleInputChange}
                        select
                        error={errors.state}
                        sx={{
                            width: '100%',
                        }}
                    >
                        {
                            states.map((state, index) => {
                                return (<MenuItem key={index} value={state}>{state}</MenuItem>)
                            })
                        }
                    </TextInput> :
                    <TextInput
                        type='text'
                        label="State"
                        name="state"
                        value={values.state || ''}
                        placeholder="State"
                        onChange={handleInputChange}
                        error={errors.state}
                        sx={{
                            width: '100%'
                        }}
                    />
                }
            </Grid>

            <Grid item xs={6} >
                <TextInput
                    type='text'
                    label="Postal Code"
                    disabled={zipDisplay.disabled}
                    name="postal_code"
                    value={values.postal_code || ''}
                    placeholder={zipPattern.placeholder}
                    onChange={handleInputChange}
                    error={errors.postal_code}
                    inputProps={{ inputMode: 'numeric', pattern: zipPattern.pattern }}
                    sx={{
                        width: '100%',
                        backgroundColor: zipDisplay.backgroundColor
                    }}
                />
            </Grid>

            <Grid item xs={6} >
                <CountryAbbrev country={country} setCountry={setCountry} handleCountryChange={handleCountryChange} />
            </Grid>
        </>
    )
}


import React from 'react'
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function ActionButton(props) {
    const { children, color, onClick, disabled } = props;

    return (
        <ThemeProvider
            theme={createTheme({
                palette: {
                    primary: {
                        main: '#7986cb',
                        dark: '#3f51b5'
                    },
                    secondary: {
                        main: '#ff4081',
                        dark: '#f50057'
                    }
                }
            })}
        >
            <Button
                onClick={onClick}
                variant={"contained"}
                color={color || "primary"}
                disabled={disabled || false}
                sx={{
                    minWidth: 0,
                    margin: 0.5,
                    px: 1,
                    textTransform: 'capitalize'
                    // // only hard-code color in @mui/material, unlike theme.palette.primary.light in @material-ui/core
                    // backgroundColor: '#7986cb',
                    // color: '#3f51b5'

                    // // only hard-code color in @mui/material, unlike theme.palette.secondary.light in @material-ui/core
                    // backgroundColor: '#ff4081',
                    // color: '#f50057',
                }}
            >
                {children}
            </Button>
        </ThemeProvider>
    )
}


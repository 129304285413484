import React, { useEffect } from 'react';
import useLogout from '../hooks/useLogout';

function Logout() {
    const logout = useLogout();

    useEffect(() => {
        (async () => {
            await logout();
        })();
    }, []);

    return (
        <>
            <h1>Logout...</h1>
        </>
    );
}

export default Logout;

import React from 'react';
import TextField from '@mui/material/TextField';

export default function TextInput(props) {

    const { name, label, value, size, error = null, onChange, ...other } = props;
    return (
        <TextField
            variant="outlined"
            size={size || "small"}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            {...other}
            {...(error && { error: true, helperText: error })}
        />
    )
}
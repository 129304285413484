import React, { useState } from 'react';
import ForgotPassword from "../../components/public/ForgotPassword";
import ResetPassword from '../../components/public/ResetPassword';

function ForgotPasswordAllInOne() {
    const [email, setEmail] = useState('');
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    return (
        isEmailVerified ?
            <ResetPassword email={email} /> :
            <ForgotPassword email={email} setEmail={setEmail} setIsEmailVerified={setIsEmailVerified} />
    );
}

export default ForgotPasswordAllInOne;

import { useForm } from '../common/useForm';

let startCard = {
    id: 0,
    name: '',
    email: '',
    country: 'US',
    line1: '',
    city: '',
    state: '',
    postal_code: ''
}

export function useCard(initCard = startCard) {

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? ((/.+@.+\..+/).test(fieldValues.email) ? "" : "Email is not valid.") : "This field is required."

        if ('line1' in fieldValues)
            temp.line1 = fieldValues.line1 ? "" : "This field is required."
        if ('city' in fieldValues)
            temp.city = fieldValues.city ? "" : "This field is required."
        if ('state' in fieldValues)
            temp.state = fieldValues.state ? "" : "This field is required."
        if ('postal_code' in fieldValues)
            temp.postal_code = fieldValues.postal_code ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues === values) {
            return Object.values(temp).every(x => x === "")
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initCard, { ...initCard }, true, validate);

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        validate
    }
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';
import { TextField, Button, MenuItem, Grid } from '@mui/material';
import useAppContext from '../../hooks/useAppContext';
import { makeStyles } from '@mui/styles';
import { setLogin } from '../../browser/localStorage';
import displayError from '../../errors/displayError';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    middle: {
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

// select store from multiple stores owned by the same owner
const SelectStore = ({ email, refreshToken, storeUsers }) => {
    const { setAuth, setStore } = useAppContext();
    const classes = useStyles();
    const navigate = useNavigate();
    const [storeId, setStoreId] = useState(storeUsers.length > 0 ? storeUsers[0].storeId : null);
    const [errMsg, setErrMsg] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !refreshToken || !storeId) return;

        try {
            const { data } = await AuthService.storeLogin({ email, refreshToken, storeId });
            const { store, auth } = data;
            setAuth(auth);
            setStore(store);
            setLogin();
            navigate('/orders');
        }
        catch (err) {
            displayError(err, setErrMsg);
        }
    };


    return (
        <>
            <p className={errMsg ? "alert-danger login-container" : "offscreen login-container"} aria-live="assertive" >{errMsg}</p >
            <h1>Please select an associated store</h1>
            <form className="form login-container" onSubmit={handleSubmit}>
                <Grid container spacing={4} sx={{ mt: 4 }}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Store"
                            variant="outlined"
                            size="small"
                            name="store"
                            value={storeId}
                            placeholder="State"
                            onChange={(e) => setStoreId(e.target.value)}
                            select
                            sx={{
                                width: '100%',
                            }}
                        >
                            {
                                storeUsers.map((storeUser, index) => {
                                    return (<MenuItem key={index} value={storeUser.storeId}>{storeUser.storeName}</MenuItem>)
                                })
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={12} className={classes.middle}>
                        <Button
                            type='submit'
                            variant="contained"
                            sx={{
                                width: '100%'
                            }}>
                            Continue
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </>
    );
};


export default SelectStore;

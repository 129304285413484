import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CardForm from './CardFormWithoutValidation';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardContainer = ({ addOrEdit, recordForEdit }) => {
    return (
        <Elements stripe={stripeTestPromise}>
            <CardForm addOrEdit={addOrEdit} recordForEdit={recordForEdit} />
        </Elements>

    )
}

export default CardContainer;

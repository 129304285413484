import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Platforms } from '../../data'

export default function PlatformSelect({ handlePlatformChange }) {
  const [platform, setPlatform] = useState(null); // displayed in the input
  return (
    <Autocomplete
      options={Platforms}
      freeSolo
      autoHighlight
      getOptionLabel={(option) => option.label}
      disableClearable
      size="small"
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option.label}
        </Box>
      )}
      name="platform"
      value={platform}
      onChange={(event, newPlatform) => {
        setPlatform(newPlatform);
        handlePlatformChange(newPlatform.label);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose or type a platform"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}


import useAuth from './useAppContext';
import axios from '../axios';

// Two purposes
// 1, get the access token automatically when one tab is logged in; save repetitive logins
// 2, renew the access token when the access token expired
const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/api/v1/auth/refresh', {
            withCredentials: true
        });

        //point: It can only be used by useAxiosPrivate
        //It will cause dead loop if it used by multiple tabs/windows (open link in New Tab):
        // refresh successfully in PersistentLogin -> auth.user is absent in RequireAuth ->
        // navigate to /login -> refresh successfully in login -> persistentLogin (no need of refresh)
        // -> auth.user is absent in RequireAuth
        // solution: useRefreshTokenLikeLogin
        setAuth(prev => {
            return {
                ...prev,
                accessToken: response.data.accessToken
            };
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken;

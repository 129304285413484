const StoreService = {
    getStoreInfo(axiosPrivate, storeId) {
        return axiosPrivate.get(`/api/v1/stores/${storeId}`);
    },

    getStores(axiosPrivate) {
        return axiosPrivate.get('/api/v1/stores');
    },

    removeStoreInfo(axiosPrivate, storeId) {
        return axiosPrivate.delete(`/api/v1/stores/remove_store_info/${storeId}`);
    },

    removeStoreAccount(axiosPrivate, storeId) {
        return axiosPrivate.delete(`/api/v1/stores/${storeId}`);
    }
}

export default StoreService;
const defaultState = {
  list: [],
  isModalOpen: false,
  modalContent: ''
};

const handleSubmit = async (e, dispatch, name, setName, add, axiosPrivate) => {
  e.preventDefault();
  if (name) {
    const { data } = await add(axiosPrivate, name);
    const newItem = { _id: data._id, name };
    dispatch({ type: 'ADD_ITEM', item: newItem });
    setName('');
  } else {
    dispatch({ type: 'NO_VALUE' });
  }
}

const closeModal = (dispatch) => {
  dispatch({ type: 'CLOSE_MODAL' })
}

const handleRemove = async (item, dispatch, remove, axiosPrivate) => {
  const response = await remove(axiosPrivate, item.name);
  console.log(response.data);
  dispatch({ type: 'REMOVE_ITEM', _id: item._id })
}

export { defaultState, handleRemove, handleSubmit, closeModal };
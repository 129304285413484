import React, { useState, useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, List, CssBaseline, Divider, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import menu from '../../../menu/Menu';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import SearchBar from '../../../components/SearchBar';
import { grey } from "@mui/material/colors";
import Logo from '../Logo';
import useAppContext from '../../hooks/useAppContext';

import { Drawer, DrawerHeader, AppBar } from './Drawer';
import Header from './Header';

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(window.innerWidth > 480);
  const { store } = useAppContext();
  const topOffset = 30;
  const [tierAlert, setTierAlert] = useState(false);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTierAlert(store ? store.inspectedOrderNo > store.maxOrderNo : false);
    setTop(store && store.inspectedOrderNo > store.maxOrderNo ? topOffset : 0);
  }, [store]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const location = useLocation();
  const path = location.pathname;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {tierAlert && store &&
        <Header topOffset={topOffset} store={store} />
      }
      <AppBar position="fixed" open={open}
        sx={{
          top,
          borderBottom: 3,
          borderColor: '#00e6e6',
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <SearchBar />

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            top,
            backgroundColor: grey[200],
            color: "black"
          }
        }}>
        <DrawerHeader>
          <Logo />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menu.map((item, index) => (
            item.children ?
              <SidebarItemCollapse key={index} item={item} path={path} drawerOpen={open} textColor={'black'} firstLevel={true} /> :
              <SidebarItem key={index} item={item} path={path} drawerOpen={open} textColor={'black'} firstLevel={true} />
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{
        flexGrow: 1,
        width: '100%',
        backgroundColor: grey[100],
        overflow: "hidden",
        overflowY: "scroll"
      }}>
        {tierAlert && store && //reserve for the tierAlert
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            height: topOffset
          }} />
        }
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box >
  );
}
import { useEffect } from 'react';
import { axiosPrivate } from '../axios';
import useRefreshToken from './useRefreshToken';
import useAppContext from './useAppContext';
import { clearLogin, clearSearch } from '../browser/localStorage';

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth, setAuth, store, setStore } = useAppContext();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                // attach the storeId in the query params (after question mark, queries in express NodeJs)
                // only in get methods
                // for delete methods used in admin, the storeId is attached to the url as path parameter (not after question mark)
                if (config.method === 'get') {
                    if (!config.params) {
                        config.params = {};
                    }
                    config.params.storeId = store?._id;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    try {
                        const newAccessToken = await refresh(); // refresh use axios instead, it will not be intercepted
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
                        return axiosPrivate(prevRequest);
                    } catch (err) {
                        //Following prevents part of dead loop of infinite refresh in the same window
                        //by clearing both auth and login
                        setAuth({});
                        setStore(null);
                        clearLogin();
                        clearSearch();
                        throw err;
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh]);

    return axiosPrivate;
}

export default useAxiosPrivate;

import { Box, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import Eye from '../imgs/eye_all_transparent.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    logoImg: {
        width: 18,
        borderWidth: '1px',
        borderColor: theme.palette.primary.light
    },
}));

const appName = process.env.REACT_APP_NAME.toUpperCase();

export default function Icon() {
    const classes = useStyles();

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: purple[200] }}>
                {appName.substring(0, 2)}
            </Typography>
            <img src={Eye} alt={"Eye"} className={classes.logoImg} ></img >
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: purple[300] }}>
                {appName.substring(3, 4)}
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 1, fontWeight: 'bold', color: purple[700] }}>
                {appName.substring(4, 5)}
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[700] }}>
                {appName.substring(5, 6)}
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[600] }}>
                {appName.substring(6, 7)}
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[500] }}>
                {appName.substring(7, 8)}
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: purple[400] }}>
                {appName.substring(8, 9)}
            </Typography>
        </Box>
    )
}

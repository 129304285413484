export const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_ITEMS': {
      return {
        ...state,
        list: action.items
      };
    }
    case 'ADD_ITEM': {
      const newList = [...state.list, action.item];
      return {
        ...state,
        list: newList,
        isModalOpen: true,
        modalContent: "item added"
      };
    }
    case 'NO_VALUE': {
      return { ...state, isModalOpen: true, modalContent: 'please enter value' };
    }
    case 'CLOSE_MODAL': {
      return { ...state, isModalOpen: false };
    }
    case 'REMOVE_ITEM': {
      const newList = state.list.filter((item) => item._id !== action._id);
      return { ...state, list: newList };
    }
    case 'ITEM_EXISTED': {
      return {
        ...state,
        isModalOpen: true,
        modalContent: 'item already exists'
      };
    }
    default: throw new Error('no matching action type');
  }
}



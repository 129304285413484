export const BillingService = {
    getPlansAndDefaultPayment(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/billings/plans_default_payment`);
    },

    getSetupIntentClientSecret(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/billings/setup`);
    },
    getPaymentMethods(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/billings/payment_methods`);
    },
    finishSetupPaymentMethod(axiosPrivate, { payment_method, isDefault }) {
        return axiosPrivate.post(`/api/v1/billings/finish_setup`, { payment_method, isDefault });
    },
    addPaymentMethod(axiosPrivate, { payment_method, isDefault, email }) {
        return axiosPrivate.post(`/api/v1/billings/payment_method`, { payment_method, isDefault, email });
    },
    getDefaultPaymentMethod(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/billings/default_payment_method`);
    },
    setDefaultPaymentMethod(axiosPrivate, { payment_method, email }) {
        return axiosPrivate.put(`/api/v1/billings/default_payment_method`, { payment_method, email });
    },
    removePaymentMethod(axiosPrivate, { payment_method }) {
        return axiosPrivate.delete(`/api/v1/billings/payment_method/${payment_method}`);
    },

    getSubscriptions(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/billings/subscriptions`);
    },
    getSubscription(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/billings/subscription`);
    },
    subscribe(axiosPrivate, { priceId, email }) {
        return axiosPrivate.post(`/api/v1/billings/subscribe`, { priceId, email });
    },
    subscribeBackend(axiosPrivate, { priceId, payment_method, email }) {
        return axiosPrivate.post(`/api/v1/billings/subscribe_backend`, { priceId, payment_method, email });
    },

    // can not use intercept in useAxiosPrivate because the url is not fixed
    cancel(axiosPrivate, { subscriptionId, storeId }) {
        return axiosPrivate.delete(`/api/v1/billings/subscriptions/${subscriptionId}/${storeId}`);
    }
}

import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { AuthService } from '../../services/AuthService';
import { makeStyles } from '@mui/styles';
import displayError from '../../errors/displayError';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    middle: {
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function ForgotPassword({ email, setEmail, setIsEmailVerified }) {
    const classes = useStyles();
    const emailRef = useRef();
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) return;

        AuthService.forgotPassword(email)
            .then(response => {
                console.log(response.data); // debug mode

                setIsEmailVerified(true);
            })
            .catch(err => {
                displayError(err, setErrMsg);
            });
    };


    return (
        <>
            <p className={errMsg ? "alert-danger login-container" : "offscreen login-container"} aria-live="assertive" >{errMsg}</p >
            <h1>Forgot Password</h1>
            <form className="login-container" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            ref={emailRef}
                            type='email'
                            label="Account Email"
                            variant="outlined"
                            size="small"
                            name='email'
                            value={email}
                            sx={{
                                width: '100%',
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.middle}>
                        <Button
                            type='submit'
                            variant="contained"
                            sx={{
                                width: '100%'
                            }}>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default ForgotPassword;

import useAppContext from './useAppContext';
import axios from '../axios';

// Two purposes
// 1, get the access token automatically when one tab is logged in; save repetitive logins
// 2, renew the access token when the access token expired
const useRefreshTokenLikeLogin = () => {
    const { setAuth, setStore } = useAppContext();

    const refreshLikeLogin = async () => {
        const response = await axios.get('/api/v1/auth/refresh_like_login', {
            withCredentials: true
        });

        // Following prevents dead loop of infinite refresh in useRefreshToken
        // set both user and accessToken
        const { store, auth } = response.data;
        setAuth(auth);
        setStore(store);
    }
    return refreshLikeLogin;
}

export default useRefreshTokenLikeLogin;

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    flagged: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(-0.9),
        padding: theme.spacing(0.3),
        color: "#ff0000",
        backgroundColor: "#ffb3b3",
        borderRadius: 10
    }

}));


import React, { useState, useEffect } from 'react';
import UserForm from "../../components/user/UserForm";
import { Paper, Box, TableBody, TableRow, TableCell, InputAdornment, Grid } from '@mui/material';
import { Button, ActionButton, TextInput } from "../../components/common/inputs";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAppContext from '../../hooks/useAppContext';
import StoreUserService from "../../services/StoreUserService";
import { useTable, Popup, PageHeader, Notification, ConfirmDialog } from '../../components/common';
import { useBaseStyles } from '../../styles';
import notifyError from '../../errors/notifyError';

const headCells = [
    { id: 'role', label: 'Role' },
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]

export default function Users() {
    const baseClasses = useBaseStyles();
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [openPopup, setOpenPopup] = useState(false);
    const [msg, setMsg] = useState(null);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth, store } = useAppContext();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    useEffect(() => {
        if (!store) {
            setNotify({
                isOpen: true,
                message: 'Please Select a Store',
                type: 'error'
            })
            return;
        }
        let isMounted = true;
        const controller = new AbortController();
        StoreUserService.getUsers(axiosPrivate)
            .then(response => {
                isMounted && setRecords(response.data.users);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, store]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);


    const addOrEdit = async (employee, resetForm) => {
        try {
            if (employee._id === 0) {
                const { data } = await StoreUserService.addUser(axiosPrivate, employee, store.storeName);
                const newUser = { ...employee, _id: data._id };
                console.log(newUser);
                setRecords([...records, newUser]);
                setMsg("Account created successfully. Instructions for activating their account has been emailed to the user.");
                setTimeout(() => setMsg(""), 10000);
            }
            else {
                await StoreUserService.editUser(axiosPrivate, employee)
                const newRecords = [...records];
                let recordIndex = newRecords.findIndex(x => x._id === employee._id);
                newRecords[recordIndex] = { ...employee }
                setRecords([...newRecords]);
                setNotify({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                })
            }

            resetForm()
            setRecordForEdit(null)
            setOpenPopup(false)
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }
    }

    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    return items.filter(x => x.userName ? x.userName.toLowerCase().includes(target.value.toLowerCase()) : false)
            }
        })
    }

    const handleDelete = async id => {
        try {
            await StoreUserService.removeUser(axiosPrivate, id);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            const newRecords = records.filter((item) => item._id !== id);
            setRecords(newRecords);
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }

    }

    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    return (
        <>
            <PageHeader
                title="Settings"
                subTitle="Users"
            />

            <Paper className={baseClasses.pageContent}>
                <Box sx={{
                    minWidth: 700,
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <p className={msg ? "normal-feedback alert-success" : "offscreen"} aria-live="assertive">
                                {msg}
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={8} lg={9}>
                            <TextInput
                                label="Search Users"
                                size="small"
                                className={baseClasses.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                        </Grid>
                        <Grid item xs={4} lg={3} className={baseClasses.right}>
                            <Button
                                disabled={auth.user.role !== "owner"}
                                text="Invite New"
                                size="medium"
                                variant="outlined"
                                startIcon={<AddIcon />}
                                onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                            />
                        </Grid>
                    </Grid>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item._id}>
                                    <TableCell>{item.role}</TableCell>
                                    <TableCell>{item.userName}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>
                                        <ActionButton
                                            color="primary"
                                            disabled={auth.user.role !== "owner"}
                                            onClick={() => { openInPopup(item) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </ActionButton>
                                        <ActionButton
                                            color="secondary"
                                            disabled={auth.user.role !== "owner" || item.role === 'owner'}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Are you sure to delete this record?',
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => handleDelete(item._id)
                                                })
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </ActionButton>
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Box>
            </Paper>
            <Popup
                title="User Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <UserForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Button } from "../common/inputs";

export default function Header({ topOffset, store }) {
    const navigate = useNavigate();
    const StyledBox = styled(Box)(({ theme }) => ({
        zIndex: theme.zIndex.drawer + 1
    }));

    const handleUpgradePlan = () => {
        navigate(`/settings/subscribe`, { replace: true })
    }

    return (
        <StyledBox sx={{
            flexGrow: 1,
            display: 'flex',
            height: topOffset,
            backgroundColor: 'black',
            alignItems: 'center',
            position: "fixed",
            width: '100%'
        }}>
            <Typography
                sx={{
                    width: '100%',
                    color: 'white',
                    backgroundColor: 'black',
                }}
            >
                {store.inspectedOrderNo} orders exceed the monthly limit of {store.maxOrderNo} orders in the {store.plan} Tier
                <Button
                    text="Upgrade"
                    size="small"
                    onClick={handleUpgradePlan}
                    sx={{
                        pl: 10
                    }}
                />
            </Typography>

        </StyledBox>
    )
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link, Outlet } from 'react-router-dom';
import LoginDialog from './LoginDialog';
import { TOKEN_COOKIE_NAME } from "../views/constant";
import cookie from "react-cookies";
import Grid from "@mui/material/Grid";
import Logo from './Logo';

export default function ManuBar() {
  const [open, setOpen] = React.useState(false);
  //Convert anything to boolean. exist => true, not exist (undefined, null, 0, "") => false
  let hasLoggedIn = !!cookie.load(TOKEN_COOKIE_NAME);
  let buttonText = hasLoggedIn ? "Logout" : "LogIn";
  const handleClickOpen = () => {
    if (hasLoggedIn) {
      //handle log out logic
      cookie.remove(TOKEN_COOKIE_NAME);
      window.location.refresh();
    } else {
      //handle log in logic
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  }

  const appLink = process.env.REACT_APP_URL;

  return (
    <>
      <Box sx={{ flexGrow: 6 }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Grid container alignItems="center" sx={{ textAlign: 'left' }}>
              <Grid item xs={4}  >
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize', display: 'inline-block', ml: -2 }} to={appLink}>
                  <Logo />
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to="/login">Login</Button>
              </Grid>
              <Grid item xs={4} >
                <Button color="inherit" component={Link} sx={{ flexGrow: 1, textTransform: 'capitalize' }} to="/register">Register</Button>
              </Grid>
              {/* <Button color="inherit" onClick={handleClickOpen}>{buttonText}</Button> */}
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
}

import React from 'react';
import { Paper, Card, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { blue } from "@mui/material/colors";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const useStyles = makeStyles(theme => ({
    pageHeader: {
        padding: theme.spacing(1),
        display: 'flex',
        margin: theme.spacing(5)
    },
    pageIcon: {
        display: 'inline-block',
        padding: theme.spacing(2),
        color: '#3c44b1'
    },
    pageTitle: {
        paddingLeft: theme.spacing(2),
        '& .MuiTypography-subtitle2': {
            opacity: '0.6'
        }
    }
}))

export default function PageHeader(props) {

    const classes = useStyles();
    const { title, subTitle, icon } = props;
    return (

        <Paper className={classes.pageHeader} sx={{ backgroundColor: blue[100] }}>
            {icon && <Card className={classes.pageIcon}>
                {icon}
            </Card>}
            <Box display="flex" sx={{ alignItems: "center" }} className={classes.pageTitle}>
                <Typography
                    variant="h6">
                    {title}</Typography>
                {subTitle && <ChevronRightIcon />}
                <Typography
                    variant="h6">
                    {subTitle}</Typography>
            </Box>
        </Paper >

    )
}
const UserService = {
    //not called
    updateUser(axiosPrivate, user) {
        return axiosPrivate.put('/api/v1/users', user);
    },
    showMe: function (axiosPrivate) {
        return axiosPrivate.get('/api/v1/users/show_me');
    },
    updateUserName: function (axiosPrivate, name) {
        return axiosPrivate.patch('/api/v1/users', { name });
    },
    updatePassword: function (axiosPrivate, oldPassword, newPassword) {
        return axiosPrivate.patch('/api/v1/users', { oldPassword, newPassword });
    },
}

export default UserService;
import { BroadcastChannel } from 'broadcast-channel';
import useAuth from './useAppContext';

//if one tab is logged out, all other tabs are logged out

const logoutChannel = new BroadcastChannel('signout');

export const usePostMessage = () => {
    const { setAuth } = useAuth();

    const postMessage = () => {
        logoutChannel.postMessage('Logout');
        setAuth({});
    }
    return postMessage;
}

export const useLogoutAllTabs = () => {
    const { setAuth } = useAuth();

    const logoutAllTabs = () => {
        logoutChannel.onmessage = () => {
            console.log('logout all tabs')
            window.location.href = window.location.origin + "/login";
            setAuth({});
            logoutChannel.close();
        }
    }

    return logoutAllTabs;
}
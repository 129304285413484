import React from 'react'
import { Button as MuiButton } from '@mui/material';
import { grey, indigo } from "@mui/material/colors";
import SearchIcon from '@mui/icons-material/Search';
import { ThemeProvider, createTheme } from "@mui/material/styles";


export default function SearchButton(props) {
    const { disabled, text, size, color, variant, onClick, ...other } = props

    return (
        <ThemeProvider
            theme={createTheme({
                palette: {
                    grey: {
                        main: grey[300],
                        dark: grey[400],
                    },
                    primary: {
                        main: '#3f51b5',
                        dark: '#3c3799'
                    },
                    red: { //red
                        main: '#ff4081',
                        dark: '#f50057'
                    },
                    brightRed: {
                        main: '#ff1a1a',
                        dark: '#e62e00'
                    }
                }
            })}
        >
            <MuiButton
                disabled={disabled || false}
                variant={variant || "contained"}
                size={size || "large"}
                // color can only be predefined as in palette, 
                // and will report error using Hexdecial color format in @mui/material, 
                // only set color in @material-core
                color={color || "primary"}
                onClick={onClick}
                {...other}
                sx={{
                    color: indigo[900],
                    height: 38,
                    textTransform: 'capitalize'
                }}
            >
                <SearchIcon />
                {text}
            </MuiButton>
        </ThemeProvider>
    )
}
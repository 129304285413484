import React from 'react';
import Typography from '@mui/material/Typography';
import FlagIcon from '@mui/icons-material/Flag';
import { useStyles } from './styles';
import { useBaseStyles } from '../../common/styles';
import { styled } from '@mui/material/styles';

export function DisplayResult({ order }) {
    const classes = useStyles();
    const baseClasses = useBaseStyles();
    const StyledTypography = styled(Typography)(({ theme }) => ({
        //the textTransform and fontSize properties only change via sx setting, instead of classes
        textTransform: 'capitalize',
        fontSize: 13
    }));

    if (!order.processed) {
        return <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.grey_bg}`}>
            Pending
        </StyledTypography>
    }
    if (order.fraud || order.flagType !== 0) {
        return <>
            {order.fraud && <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.red_bg}`} >
                Possible Fraud
            </StyledTypography>
            }

            {order.flagType !== 0 && <FlagIcon fontSize="medium" className={classes.flagged} />}
        </>
    } else {
        return <StyledTypography variant="button" className={`${baseClasses.base} ${baseClasses.green_bg}`}
        //the textTransform and fontSize properties only change via sx setting, instead of classes
        >
            Cleared
        </StyledTypography >
    }
}
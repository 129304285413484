import { Dialog, DialogContent, DialogTitle, DialogActions, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Button } from './inputs';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        }
    }
}))

export default function ConfirmDialog(props) {
    const classes = useStyles();
    const { confirmDialog, setConfirmDialog } = props;
    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <NotListedLocationIcon fontSize="large" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography varaint="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography varaint="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    text="No"
                    color="grey"
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                />
                <Button
                    text="Yes"
                    color="red"
                    onClick={confirmDialog.onConfirm}
                />
            </DialogActions>
        </Dialog>
    )
}

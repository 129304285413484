const ZipPatterns = new Map([
["Afghanistan", { placeholder: 'xxxx', pattern: "[0-9]{4}"}],
["Åland", { placeholder: '', pattern: '(AX-)?[0-9]{5}' }],
["Albania", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Algeria", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["American Samoa", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?"}],
["Andorra", { placeholder: 'ADxxx', pattern: "AD[0-9]{3}" }],
["Angola", {placeholder: '', pattern: ''}],
["Anguilla", {placeholder: 'AI-2640', pattern: 'AI-2640'}],
["Antigua and Barbuda", {placeholder: '', pattern: ''}],
["Argentina", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Armenia", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Aruba", {placeholder: '', pattern: ''}],
["Australia",  { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Austria",  { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Azerbaijan",{ placeholder: 'AZxxxx', pattern: "AZ[0-9]{4}" }],
["Bahamas", {placeholder: '', pattern: ''}],
["Bahrain", {placeholder: 'xxx', pattern: "[0-9]{3}([0-9])?"}],
["Bangladesh", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Barbados",  { placeholder: 'BBxxxxx', pattern: "BB[0-9]{5}" }],
["Belarus", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Belgium", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Belize", { placeholder: '', pattern: ''}],
["Benin", { placeholder: '', pattern: ''}],
["Bermuda", { placeholder: '', pattern: ''}],
["Bhutan", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Bolivia", { placeholder: '', pattern: ''}],
["Bonaire, Sint Eustatius and Saba", { placeholder: '', pattern: ''}],
["Bosnia and Herzegovina", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Botswana", { placeholder: '', pattern: ''}],
["Brazil", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{3})?"}],
["British Antarctic Territory", { placeholder: 'BIQQ 1ZZ', pattern: '[bB][iI][qQ][qQ] 1[zZ][zZ]'}],
["British Indian Ocean Territory", { placeholder: 'BBND 1ZZ', pattern: '[bB][bB][nN][dD] 1[zZ][zZ]'}],
["British Virgin Islands", { placeholder: 'VGxxxx', pattern: "VG[0-9]{4}" }],
["Brunei Darussalam", { placeholder: 'xxxxxx', pattern: "[A-Za-z]{2}[0-9]{4}" }],
["Bulgaria", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Burkina Faso", { placeholder: '', pattern: ''}],
["Burundi", { placeholder: '', pattern: ''}],
["Cambodia", { placeholder: '120000', pattern: '120000'}],
["Cameroon", { placeholder: '', pattern: ''}],
["Canada", {placeholder: 'xxxxxx', pattern: '^[A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]$'}],
["Cape Verde", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Cayman Islands", { placeholder: 'KYx-xxxx', pattern: "KY[0-9]-[0-9]{4}" }],
["Central African Republic", { placeholder: '', pattern: ''}],
["Chad",{ placeholder: '', pattern: ''}],
["Chile", { placeholder: 'xxxxxxx', pattern: "[0-9]{3}-?[0-9]{4}" }],
["China", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Christmas Island",{ placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Cocos (Keeling) Islands", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Colombia", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Comoros", { placeholder: '', pattern: ''}],
["Congo, Republic of the",{ placeholder: '', pattern: ''}],
["Congo, Democratic Republic of the",{ placeholder: '', pattern: ''}],
["Cook Islands", { placeholder: '', pattern: ''}],
["Costa Rica", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Côte d'Ivoire (Ivory Coast)",  { placeholder: '', pattern: ''}],
["Croatia",  { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Cuba",  { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Curacao",{ placeholder: '', pattern: ''}],
["Cyprus", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Czech Republic", { placeholder: 'xxx xx', pattern: "[0-9]{3} [0-9]{2}"}],
["Denmark", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Djibouti", { placeholder: '', pattern: ''}],
["Dominica", { placeholder: '', pattern: ''}],
["Dominican Republic", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Timor-Leste", { placeholder: '', pattern: ''}],
["Ecuador", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Egypt", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["El Salvador", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Equatorial Guinea", { placeholder: '', pattern: ''}],
["Eritrea", { placeholder: '', pattern: ''}],
["Estonia", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Eswatini", { placeholder: 'xxxx', pattern: "[a-zA-Z][0-9]{3}" }],
["Ethiopia", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Falkland Islands (Malvinas)", { placeholder: 'FIQQ 1ZZ', pattern: '[fF][iI][qQ][qQ] 1[zZ][zZ]'}],
["Faroe Islands", { placeholder: 'FO-xxx', pattern: "FO-[0-9]{3}" }],
["Fiji", { placeholder: '', pattern: ''}],
["Finland", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["France", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["French Guiana", { placeholder: '973xx', pattern: "973[0-9]{2}" }],
["French Polynesia", { placeholder: '987xx', pattern: "987[0-9]{2}" }],
["French Southern and Antarctic Territories", { placeholder: '', pattern: ''}],
["Gabon", { placeholder: '', pattern: ''}],
["Gambia", { placeholder: '', pattern: ''}],
["Georgia", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Germany", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Ghana", { placeholder: 'x?xxx', pattern: "[a-zA-Z]{2}[0-9]{3}[0-9]?[0-9]?"}],
["Gibraltar", { placeholder: 'GX11 1XX', pattern: 'GX11 1[a-zA-Z]{2}'}],
["Greece", { placeholder: 'xxx xx', pattern: '[0-9]{3} [0-9]{2}'}],
["Greenland", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Grenada", { placeholder: '', pattern: ''}],
["Guadeloupe", { placeholder: '971xx', pattern: "971[0-9]{2}" }],
["Guam", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["Guatemala", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Guernsey", { placeholder: 'xxx xxx', pattern: "[a-zA-Z]{2}[0-9][0-9]? [0-9][a-zA-Z]{2}" }],
["Guinea", { placeholder: 'xxx', pattern: "[0-9]{3}" }],
["Guinea-Bissau", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Guyana", { placeholder: '', pattern: ''}],
["Haiti", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Heard Island and McDonald Islands", { placeholder: '', pattern: ''}],
["Honduras", { placeholder: 'xxxxx', pattern: '([a-zA-Z]{2}|[0-9])[0-9]{4}'}],
["Hong Kong", { placeholder: '', pattern: ''}],
["Hungary", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Iceland", { placeholder: 'xxx', pattern: "[0-9]{3}" }],
["India",  { placeholder: 'xxxxxx', pattern: "[0-9]{3}[ ]?[0-9]{3}" }],
["Indonesia", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Iran, Islamic Republic of", { placeholder: 'xxxxx-xxxxx', pattern: "[0-9]{5}-[0-9]{5}" }],
["Iraq", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Ireland", { placeholder: 'xxx', pattern: "[a-zA-Z][0-9]{2}" }],
["Isle of Man", { placeholder: 'IMX XXX', pattern: 'IM[0-9][0-9]?[ ][0-9][a-zA-Z]{2}'}],
["Israel", { placeholder: 'xxxxxxx', pattern: "[0-9]{7}" }],
["Italy", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Jamaica", { placeholder: 'xx', pattern: "[0-9]{2}" }],
["Japan", { placeholder: 'xxx-xxxx', pattern: "[0-9]{3}-[0-9]{4}" }],
["Jersey", { placeholder: 'JEx xxx', pattern: 'JE[0-9][0-9]?[ ][0-9][a-zA-Z]{2}'}],
["Jordan", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Kazakhstan", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Kenya", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Kiribati", { placeholder: '', pattern: ''}],
["Korea, Democratic People's Republic of", { placeholder: '', pattern: ''}],
["Korea, Republic of", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Kosovo", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Kuwait", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Kyrgyzstan", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Lao People's Democratic Republic", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Latvia", { placeholder: 'LV-XXXX', pattern: "LV-[0-9]{4}" }],
["Lebanon", { placeholder: 'xxxxx', pattern: "[0-9]{5}|[0-9]{4}-[0-9]{4}" }],
["Lesotho", { placeholder: 'xxx', pattern: "[0-9]{3}" }],
["Liberia", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Libya",  { placeholder: '', pattern: ''}],
["Liechtenstein", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Lithuania", { placeholder: 'LT-XXXXX', pattern: "LT-[0-9]{5}" }],
["Luxembourg", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Macao", { placeholder: '', pattern: ''}],
["Madagascar", { placeholder: 'xxx', pattern: "[0-9]{3}" }],
["Malawi", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Malaysia", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Maldives", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Mali", { placeholder: '', pattern: ''}],
["Malta", { placeholder: 'xxx xxxx', pattern: '[a-zA-Z]{3}[ ][0-9]{4}'}],
["Marshall Islands", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["Martinique", { placeholder: '972xx', pattern: '972[0-9]{2}'}],
["Mauritania", { placeholder: '', pattern: ''}],
["Mauritius", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Mayotte", { placeholder: '976xx', pattern: '976[0-9]{2}'}],
["Mexico", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Micronesia, Federated States of", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["Moldova, Republic of",{ placeholder: 'MDXXXX', pattern: "MD-?[0-9]{4}" }],
["Monaco", { placeholder: '980xx', pattern: '980[0-9]{2}'}],
["Mongolia", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Montenegro", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Montserrat", {placeholder: 'MSR xxxx', pattern: "MSR[ ][0-9]{4}"}],
["Morocco", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Mozambique", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Myanmar", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Namibia", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Nauru", { placeholder: '', pattern: ''}],
["Nepal", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Netherlands", { placeholder: '', pattern: ''}],
["New Caledonia", { placeholder: '988xx', pattern: '988[0-9]{2}'}],
["New Zealand", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Nicaragua", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Niger", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Nigeria", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Niue", { placeholder: '', pattern: ''}],
["Norfolk Island", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["North Macedonia", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Northern Mariana Islands", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["Norway", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Oman", { placeholder: 'xxx', pattern: "[0-9]{3}" }],
["Pakistan", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Palau", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["Palestine, State of", { placeholder: 'xxx', pattern: "[0-9]{3}" }],
["Panama", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Papua New Guinea", { placeholder: 'xxx', pattern: "[0-9]{3}" }],
["Paraguay", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Peru", { placeholder: 'xxxxx', pattern: "[0-9]{5}|[pP][eE][ ][0-9]{4}" }],
["Philippines", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Pitcairn", { placeholder: 'PCRN 1ZZ', pattern: '[pP][cC][rR][nN] 1[zZ][zZ]'}],
["Poland", { placeholder: 'xx-xxx', pattern: "[0-9]{2}-[0-9]{3}" }],
["Portugal", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Puerto Rico", { placeholder: 'xxxxx', pattern: "[0-9]{5}|[pP][eE][ ][0-9]{4}" }],
["Qatar", { placeholder: '', pattern: ''}],
["Reunion", { placeholder: '974xx', pattern: '974[0-9]{2}'}],
["Romania", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Russian Federation", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Rwanda", { placeholder: '', pattern: ''}],
["Saint Barthélemy", { placeholder: '97133', pattern: '97133'}],
["Saint Helena", { placeholder: 'XXXX 1ZZ', pattern: '[a-zA-Z]{4}[ ]1[zZ][zZ]'}],
["Saint Kitts and Nevis", { placeholder: 'KNXXXX', pattern: "[kK][nN][0-9]{4}" }],
["Saint Lucia", { placeholder: 'LCXX XXX', pattern: "[lL][cC][0-9]{2}[ ][0-9]{3}" }],
["Saint Martin (French part)", { placeholder: '97150', pattern: '97150'}],
["Saint Pierre and Miquelon", { placeholder: '97500', pattern: '97500'}],
["Saint Vincent and the Grenadines", { placeholder: 'VCXXXX', pattern: "[vV][cC][0-9]{4}" }],
["Samoa", { placeholder: 'WSXXXX', pattern: "[wW][sS][0-9]{4}" }],
["San Marino", { placeholder: '4789x', pattern: '4789[0-9]'}],
["Sao Tome and Principe", { placeholder: '', pattern: ''}],
["Saudi Arabia", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["Senegal", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Serbia", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Seychelles", { placeholder: '', pattern: ''}],
["Sierra Leone", { placeholder: '', pattern: ''}],
["Singapore", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Sint Maarten (Dutch part)", { placeholder: '', pattern: ''}],
["Slovakia", { placeholder: 'xxx xx', pattern: "[0-9]{3}[ ][0-9]{2}" }],
["Slovenia", { placeholder: 'xxxx', pattern: "([s|S][i|I]-)?[0-9]{4}" }],
["Solomon Islands",  { placeholder: '', pattern: ''}],
["Somalia",  { placeholder: 'xx xxxxx', pattern: '[a-zA-Z]{2}[ ][0-9]{5}'}],
["South Africa", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["South Georgia and the South Sandwich Islands", { placeholder: 'SIQQ 1ZZ', pattern: '[sS][iI][qQ][qQ][ ]1[zZ][zZ]'}],
["South Sudan", { placeholder: '', pattern: ''}],
["Spain", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Sri Lanka", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Sudan", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Suriname", { placeholder: '', pattern: ''}],
["Svalbard and Jan Mayen", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Sweden", { placeholder: 'xxx xx', pattern: "[0-9]{3}[ ][0-9]{2}" }],
["Switzerland", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Syrian Arab Republic", { placeholder: '', pattern: ''}],
["Taiwan, Republic of China", { placeholder: 'xxx', pattern: "[0-9]{3}(-[0-9]{2,3})?" }],
["Tajikistan", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["United Republic of Tanzania", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Thailand", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Togo", { placeholder: '', pattern: ''}],
["Tokelau", { placeholder: '', pattern: ''}],
["Tonga", { placeholder: '', pattern: ''}],
["Trinidad and Tobago", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Tunisia", { placeholder: 'xxxx', pattern: "[0-9]{4}" }],
["Turkey", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Turkmenistan", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Turks and Caicos Islands", { placeholder: 'TKCA 1ZZ', pattern: '[tT][kK][cC][aA] 1[zZ][zZ]'}],
["Tuvalu", { placeholder: '', pattern: ''}],
["US Virgin Islands", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["Uganda", { placeholder: '', pattern: ''}],
["Ukraine", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["United Arab Emirates", { placeholder: '', pattern: ''}],
["United Kingdom", { placeholder: 'x[x]x[x]', pattern: '[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?'}],
["United States", { placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" }],
["United States Minor Outlying Islands", { placeholder: '96898', pattern: '96898'}],
["Uruguay", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Uzbekistan", { placeholder: 'xxxxxx', pattern: "[0-9]{6}" }],
["Vanuatu", { placeholder: '', pattern: ''}],
["Holy See (Vatican City State)", { placeholder: '00120', pattern: '00120'}],
["Venezuela", { placeholder: 'xxxx', pattern: "[0-9]{4}(-[a-zA-Z])?" }],
["Vietnam", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Wallis and Futuna", { placeholder: '986xx', pattern: "986[0-9]{2}" }],
["Yemen", { placeholder: '', pattern: ''}],
["Zambia", { placeholder: 'xxxxx', pattern: "[0-9]{5}" }],
["Zimbabwe", { placeholder: '', pattern: ''}],
]);

export default ZipPatterns;
import React, { useEffect } from 'react';
import { Typography, Button, Grid } from '@mui/material';

const ResendOtp = ({ isTimeout, setIsTimeout, handleResend }) => {

    useEffect(() => {
        if (!isTimeout) {
            setTimeout(() => setIsTimeout(true), 60000); //60 seconds
        }
    }, [isTimeout]);

    return (
        <>
            <Grid item xs={12} textAlign='center' >
                {isTimeout ?
                    <Button
                        onClick={handleResend}
                        sx={{
                            width: '50%',
                            ':hover': {
                                bgcolor: 'primary.secondary', // theme.palette.primary.main
                                color: 'black',
                            },
                        }}>
                        Resend code
                    </Button> :
                    <Typography>
                        If you haven’t received a code, please wait 60 seconds to request a new code.
                        Please note that if you try signing in again,
                        a new verification code will be sent to you and the previous one will expire.
                    </Typography>
                }
            </Grid>
        </>
    );
};


export default ResendOtp;
